  .signupSection{
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.signupBox{
  width: 100%;
  max-width: 900px;
  display: flex;
  gap: 50px;
  box-shadow:  0px 0px 30px -10px #F0CEFF;
  padding: 70px 50px;
  margin: 50px;
  border-radius: 20px;
  position: relative;
}
.signupDetails{
  width: 300px;
  
}

.signupNewAccCreate{
  color: white;
}
.signupNewAccCreateBox{
  width: 237px;
  height: 40px;
  background-color: #E997FD;
  border-radius: 20px 0px 27px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}


.signupDetailsSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 90%;
}
.signupDetailsSectionHead{
  color: #6C156E;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin: 50px 0px;
}
.signupDetailsSectionSubhead{
  color: #6C156E;
  font-size: 20px;
  font-weight: 550;
  width: 100%;
  margin: 30px;
  text-align: left;
}
.signupBtn{
  border: none;
  color: white;
  background-color: #6C156E;
  width: 100%;
  padding: 10px 0px;
  border-radius: 20px;
  cursor: pointer;
}

.signupSeperate{
  color: #6C156E;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 567;
line-height: normal;
}
.signupInput input{
  border-radius: 30px;
  border: 1px solid #853C88;
  background: #FFF;
  padding: 10px 20px;
}

.signupInput{
  margin: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.signupInput span{
  background-color: #BA68C8;
  width: 30%;
  font-size: 12px;
  padding: 8px 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  position: absolute;
  top: -25px;
  z-index: -1;
}
.signupImg img{
    width: 100%;
    height: 100%;
}

.AlternativeOption{
  color: #853C88;
  font-size: 12px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AlternativeOption button{
  border: none;
  color: black;
  background: none;
  font-weight: bolder;
  cursor: pointer;
  font-size: 12px;
  padding: 10px;
}


@media (max-width: 900px) {
  .signupImg{
    display: none;
  }
}