.paymentSuccessSection{
    width: 100%;
    display: flex;
    justify-content: center;
}
.paymentSuccessBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    /* border: 2px solid; */
    margin: 40px;
    padding: 30px;
    gap: 30px;
}
.paymentSuccessInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.paymentSuccessImg img{
    width: 30%;
}
.paymentSuccessImg{
    display: flex;
    justify-content: center;
}
.paymentSuccessBtns{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
.paymentSuccessBtns button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymentSuccesHead{
    font-size: 30px;
    font-weight: 700;
    color: #6C156E;
}
.paymentSuccesSubhead{
    font-size: 30px;
    color: #6C156E;
    opacity: 0.5;
}

.paymentSuccesId{
    color: #6C156E;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(186, 104, 200, 0.41), rgba(255, 255, 255, 0.91));
    width: 140%;
    text-align: center;
    padding: 10px 0px;
}

.paymentSuccessDwndBtn{
    border: none;
    color: white;
    background-color: #6C156E;
    width: 100%;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
}
.paymentSuccessProfileBtn{
    border: none;
    background: none;
    padding: 8px 50px;
    color: #6C156E;
    border: 2px solid #6C156E;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
}

.PaymentSuccessdownloadIcon{
    position: absolute;
    right: 10px;
}
.PaymentSuccessProfileIcon{
    position: absolute;
    right: 10px;
    color: #6C156E;
}