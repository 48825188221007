.issuesHeading{
    text-align: center;
    font-size: 30px;
    color: #8404D9;
    width: 100%;
}

.allIssues{
    width: 100%;
    padding: 10px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}

.issuesSection ul ul{
    width: 400px;
    padding: 10px;
    background-color: #8404D9;
    margin: 10px;
    border-radius: 10px;
}

.issuesSection ul li{
    list-style-type: none;
    color: white;
    margin: 10px;

}

.issuesSection ul ul button{
    padding: 10px 20px;
    margin: 10px;
    color: #8404D9;
    background-color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}





/* Reslove Issue*/
/* Reslove Issue*/
/* Reslove Issue*/
.issueResolveMainSection{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.issueResolveProblemImageDiv{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.issueResolveProblemImageDiv h2{
    width: 100%;
    text-align: center;
}
.issueImage{
    width: 500px;
    height: 300px;
    background-color: #f9f9f9;
    border: 0.2px dashed black;
    border-radius: 10px;
}


.resolutionMainDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.resolutionHeading{
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
}


.resolutionMainDiv textarea{
    min-height: 260px;
    max-height: 260px;
    min-width: 460px;
    max-width: 460px;
    padding: 20px;
    background-color: #f7ecff;
    border: none;
    border-radius: 10px;
}


.resolutionButtons{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
}

.resolutionButtons button{
    padding: 10px 20px;
    margin: 20px;
    background-color: #8404D9;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}