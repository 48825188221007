.pricing-document {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .pricing-document h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .pricing-document .policy-section {
    margin-bottom: 40px;
  }
  
  .pricing-document h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .pricing-document p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .pricing-document strong {
    font-weight: bold;
  }
  