.about-us {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 20px;
  }
  
  .about-us h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .about-us p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .about-us ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .about-us ul li {
    margin-bottom: 5px;
  }
  