.paymentFaliureSection{
    width: 100%;
    display: flex;
    justify-content: center;
}
.paymentFailureBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    /* border: 2px solid; */
    margin: 40px;
    padding: 30px;
    gap: 30px;
}
.paymentFailureInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.paymentFailureImg img{
    width: 30%;
}
.paymentFailureImg{
    display: flex;
    justify-content: center;
}
.paymentFailureBtns{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
.paymentFailureBtns button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymentFailureHead{
    font-size: 30px;
    font-weight: 700;
    color: #6C156E;
}
.paymentFailuresubhead{
    font-size: 40px;
    color: black;
}


.paymentFailureId{
    color: #fff;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(186, 104, 200, 0.41), rgba(255, 255, 255, 0.91));
    width: 140%;
    text-align: center;
    padding: 10px 0px;
}


.paymentFailureErrorMsg{
}

.paymentFailureBtns{
    display: flex;
    justify-items: center;
    align-items: center;
}
.paymentFailureBtns button{
    border: none;
    color: white;
    background-color: #6C156E;
    width: 160%;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
}
.paymentFailureProfileBtn{
    border: none;
    background: none;
    padding: 8px 50px;
    color: #6C156E;
    border: 2px solid #6C156E;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
}

.PaymentFailuresProfileIcon{
    position: absolute;
    right: 10px;
    color: #6C156E;
}

.PaymentFailureRetryloadIcon{
    position: absolute;
    right: 10px;
    color: #fff;
}