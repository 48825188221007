/* YourComponent.css */

.userOrderDiv {
    /* Add styles for the outer container, if needed */
  }
  
  .userOrderTable {
    border-collapse: collapse;
    width: 100%;
  }
  
  .userOrderTable th,
  .userOrderTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .userOrderTable th {
    background-color: #f2f2f2;
  }
  
  .userOrderTable tr:hover {
    background-color: #f5f5f5;
  }
  
  .userOrderTable input[type="radio"] {
    margin-right: 5px;
  }
  
  /* Responsive Styles */
  @media only screen and (max-width: 600px) {
    .userOrderTable th,
    .userOrderTable td {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  }
  
  @media only screen and (max-width: 500px) {
    .userOrderTable th,
    .userOrderTable td {
      padding: 6px; /* Adjust padding for smaller screens */
    }
  }
  