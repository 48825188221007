
#useridm {
      border: 3px solid #e3e3e3;
      border-radius: 4px;
      padding: 20px;
  
.card {
        width: 250px;
        height: 300px;
        overflow: auto;
      }
  
      #circle-avatar {
        display: grid;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #ffbd30;
        place-items: center;
  
        span {
          font-size: 48px;
          font-weight: bold;
        }
      }
    }
  
.user-popupcard {
    width: 250px;
    margin-left: 10px;
    overflow-wrap : break-word;
    padding: 20px;
    /* overflow: auto;  */
  }
  .user-popupcard-login {
    width: 150px;
    /* margin-left: 10px; */
    overflow-wrap : break-word;
    padding: 15px;
    /* overflow: auto;  */
  }
#user-circle-avatar {
      display: grid;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #ffbd30;
      place-items: center;
    }
.user-span {
  font-size: 48px;
  font-weight: bold;
  padding: 20px;
}