.AdminDashboardSection{
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


.AdminDasboardBox{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.AdminDashboardAccessBtns{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

.AdminDashboardBtn{
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
}

.AdminDashboardBtn:hover{
    background-color: #c9ebff;
}

.AdminDashboardBtn:active{
    background-color: #ddf3ff;
}

.clickedButton{
    color: white;
    background-color: #00A3FF;
}

.clickedButton:hover{
    background-color: #00A3FF;
}