.contact-us {
    max-width: 600px;
    margin:100px;
    /* margin: 20px; */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .contact-us h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .contact-us p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .contact-us ul {
    list-style: none;
    padding-left: 0;
  }
  
  .contact-us li {
    margin-bottom: 10px;
  }
  
  .contact-us strong {
    font-weight: bold;
  }
  