  
  /* *{
    font-family: sans-serif;
  } */
  /* .checkout{
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    /* height: 60%; 
  }
  .subscription-container {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    align-self: center;
    align-content: center;
    background-color: #F6E8FF;
    /* width: 70%;
    height: 70%; 
  }

  .subscription-card {
    border: 2px solid #ccc;
    padding: 20px;
    margin: 10px;
    width: 200px;
    height: 300px;
    background-color: black;
    color: white;
  }
  
  .subscription-card h2 {
    color: #333;
  }
  
  .subscription-card p {
    font-weight: bold;
  }
  
  .subscription-card ul {
    list-style-type: none;
    padding: 0;
  }
  
  .subscription-card li {
    margin-bottom: 5px;
  }
  
  .subscription-card button {
    background-color: #0a311e;
    color: #fff;
    /* padding: 8px 16px; 
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    width: 100%;
    height: 10%;
  /* margin-top: 16px; */
    /* position: relative; */
    /* justify-content: flex-end;
   display: flex; 
  }
  

  @media (max-width: 768px) {
    .subscription-container {
      /* flex-direction: column;
      justify-content: space-around; */
      /* align-items: center; 
    }
  }
  
  /* Media query for phones 
  @media (max-width: 480px) {
    .subscription-container {
      grid-template-columns: 1fr;
    }
  }
 */
 .plansHeaderSlogan{
  font-size: 50px;
  color: #6C156E;
  font-weight: 500;
}
.plansSection{
  width: 100%;
  /* height: 900px; */
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
.plansHeader{
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 600px;
}
.plansSubheader{
  padding: 40px;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 30px;
  color: #6C156E;
  font-weight: 500;
}
.plansToggleBtn{
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  gap: 5px;
  padding: 10px 20px 10px 20px;
  border-radius: 25px;
  border: none;
  background-color: white
}


.toggleText{
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}
.toggleText:hover{
  color: #00A3FF;
}

.toggleTextClicked{
  background-color: #00A3FF;
  color: white;
}
.toggleTextClicked:hover{
  color: white;
  background-color: #00A3FF;
}





.plansCardSection{
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}
.plansCardStatusNPricing{
  display: flex;
}
.plansCard{
  overflow-x: hidden;
  /* background-color: #fff8ff; */
  border: 1px solid #6C156E;
  border-radius: 20px;
  padding: 30px;
  width: 360px;
  height: 600px;
  position: relative;

}

.say{
  display: none;
  
}

.plansCardHead{
  width: 100%;
  height: 50px;
  /* background-color: yellow; */
}


.plansCardHeading{
  width: 350px;
  color: #6C156E;
  font-weight: 650;
  /* background-color: red; */
  font-size: 30px;
  text-align: left;
}

.plansCardSubHeading{
  color: #6C156E;
  font-size: 20px;
  font-weight: 700;
}
.plansCardStatus-Pricing{
  height: 50px;
  width: 100%;
  /* background-color: red; */
  display: flex;
  width: 330px;
  align-items: center;
  justify-content: space-between;
}
.plansCardStatus{
  color: white;
  padding: 5px 30px;
  height: 20px;
  background-color: #6C156E;
  border-radius: 20px;
}



.plansCardPricing{
  font-size: 50px;
  font-weight: bolder;
  color: #6C156E;
}

.plansCardStatusNPricing{
  display: flex;
  gap: 12px;
  color: #6C156E;
}

.plansCardFeatures{
  height: 300px;
  color: #6C156E;
}

.plansCardFeature{
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.plansCheckoutBtn{
  position: absolute;
  bottom: 10px;
  width: 87%;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  background-color: #6C156E;
}





/* New Css 11 Dec 2023 */
/* New Css 11 Dec 2023 */

.card-circle{
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: rgba(108, 21, 110, 0.1);
  z-index: 3;
  position: absolute;
  top: 40px;
  right: -200px;
}


.changePlanStatus{
  padding: 10px 20px;
  color: white;
  background-color: #6C156E;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
}















@media (max-width: 800px) {
  .plansHeaderImage{
    display: none;
  }
}