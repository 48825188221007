/* Footer.css */

  
  .footerSection{
    background-color: #eff9ff;
  }
  
  .footer{
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .newsLetter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    gap: 10px;
    margin-bottom: 40px; /* Add margin between newsletter and footerColumns */
  }
  
  .newsletterInput {
    min-width: 300px;
    padding: 10px 20px;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 28px;
  }
  
  .newsletterBtn {
    width: 200px;
    padding: 10px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 28px;
  }
  
  .footerColumns {
    display: flex;
    justify-content: space-between;
  }
  .footerColumnsHead{
    font-weight: bold;
    font-size: 19px;
    margin-bottom: 20px;
    color: #00A3FF;
  }
  
  .footerProductsAndServices,
  .footerLegalPages {
    flex: 1;
    margin-right: 30px;
  }
  
  .footerProductsAndServices .link,
  .footerLegalPages .link {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    text-decoration: none;
    color: #000;
  }
  
  .subFooter {
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 10px;
    margin-top: 20px;
  }
  .subHeadFirst{
    font-size: 20px;
    font-weight: 500;
  }
  