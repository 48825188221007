:root{
  --primary-color: #00A3FF;
  --second-color: #fff;
  --third-color: #000;
  --background-color: #e3f5ff;
}

.createPlanHeading{
  color: var(--primary-color);
  text-align: center;
  font-size: 30px;
}

.createPlanSection{
  display: flex;
  justify-content: center;
  align-items: center;
}

.createPlanSectionMainContent{
  max-width: 1920px;
}
.createPlanMainForm{
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  margin: 20px;
}

.createPlanMainForm input, .createPlanMainForm textarea, .createPlanMainForm label{
  width: 350px;
  min-height: 20px;
  border: none;
  border-radius: 2px;
  padding: 10px;
  border-radius: 10px;
}

.createPlanMainForm input, .createPlanMainForm textarea{
  background-color: #ffffff;
  border: 1px solid #aae0ff;
}


.createPlanInputAreaSelect{
  width: 100%;
  padding: 10px 20px;
  border: none;
  background-color: #ffffff;
  border: 1px solid #aae0ff;
  border-radius: 10px;
}


.createPlanInputBoxs{
  min-height: 700px;
  width: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  margin: 20px;
  border-radius: 15px;
  background-color: #f5fbff;
  border: 1px solid #00A3FF;
  box-shadow: 10px 10px 10px #e5f5ff;
}
.createPlanInputArea{
  width: 350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: 10px;
  margin-bottom: 20px;
}
.createPlanInputBoxs:nth-child(2){
  height: 100%;
  position: relative;
}

.createPlanInputBoxs h3{
  margin: 30px 0px;
}



.createPlanAddButton{
  font-size: 15px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  cursor: pointer;
  min-width: 100%;
  color: var(--background-color);
  background-color: var(--primary-color);
  border: none;
  padding: 10px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}


.createPlanItemDecription{
  height: 50px;
}


.createPlanList{
  max-width: 85%;
  height: auto;
  /* background-color: var(--second-color); */
}
.createPlanListItem{
  min-width: 300px;
  max-width: 300px;
  max-height: auto;
  padding: 20px;
  margin-bottom: 10px;
  list-style-type: none;
  border-bottom: 0.1px solid #00A3FF;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: var(--second-color);
  position: relative;
}

.createPlanListItem button{
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 20px;
}

.createPlanItemType{
  padding: 10px 30px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  color: #000;
}

.createPlanFinalButton{
  font-size: 15px;
  width: 340px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  color: var(--second-color);
  background-color: var(--third-color);
  cursor: pointer;
  border-radius: 20px;
}