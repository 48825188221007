
*{
    font-family: sans-serif;
    margin: 0px;
    padding: 0px;
}

html{
    /* width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; */
    padding: 0px;
    margin: 0px;
}


.homeSection{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.homeHeaderSection{
    width: 100%;
    max-width: 2120px;
    min-height: 800px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* background-image: linear-gradient(to bottom, #cfecff, white); */
}

.homeHeaderText{
    width: 400px;
    font-weight: bold;
    color: #00A3FF;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
}

.homeHeaderText h1{
    font-size: 80px;
    text-align: left;
    color: #00A3FF;
    margin: 0px;
}


.homeHeaderText h3{
    font-size: 50px;
    color: #000;
    margin: 0px;
}



.homeHeaderCardSection{
    position: relative;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeHeaderCard{
    background-color: #f8fdff;
    margin: 10px;
    width: 280px;
    height: 350px;
    border-radius: 30px;
    padding: 30px;
    position: relative;
    border-left: 1px solid #00A3FF;
    box-shadow: 10px 10px 15px #e4f5ff, inset 2px 0px 10px #e9f7ff;
}
.homeHeaderCardLogo{
    margin-top: -25%;
    margin-left: 25%;
    /* margin-bottom: -50%; */
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3fbff;
    box-shadow: 5px -5px 10px 0px #e4f5ff inset;
}


.homeHeaderCardLogo .CloudIcon{
    font-size: 60px;
    color: #00A3FF;
}


.homeHeaderCard1{
    z-index: 1;
}
.homeHeaderCard2{
    position: absolute;
    top: 0px;
    left: 20px;
    z-index: 1;
    animation: change-z-indexing 10s infinite ease-in-out;
    /* transition: 0.2s all ease-in-out; */
}

@keyframes change-z-indexing{
    0%{
        z-index: 2;
    }
    10%{
        z-index: 2;
    }
    20%{
        z-index: 2;
    }
    30%{
        z-index: -1;
    }
    40%{
        z-index: -1;
    }
    50%{
        z-index: -1;
    }
    60%{
        z-index: -1;
    }
    70%{
        z-index: -1;
    }
    80%{
        z-index: -1;
    }
    90%{
        z-index: 2;
    }
    100%{
        z-index: 2;
    }

}



.homeHeaderCardHeading{
    margin-top: 20px;
    font-size: 30px;
    font-weight: bolder;
    color: #00A3FF;
}

.homeHeaderCardSubheading{
    color: #000;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.homeHeaderCardNote{
    color: #000;
}

.homeHeaderCardBtn{
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    background-color: #00A3FF;
    padding: 10px 30px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}







/* Service Cards */
.homeProductNServicesSection{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
}
.HomeServiceSection{
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.homePNSHead{
    width: 100%;
    font-size: 40px;
    font-weight: bolder;
    text-align: left;
}
.homePNSSubhead{
    font-size: 25px;
    color: black;
    margin-bottom: 50px;
}


.homePNSHeadings{
    padding: 20px;
}
.homePNSCardSection{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.homePNSCard{
    width: 340px;
    height: 300px;
    padding: 40px;
    margin: 30px;
    border-radius: 30px;
    position: relative;
    background-color: rgba(90, 24, 154, 0.05);
}

.homePNSCardHeadings{
    color: #5A189A;
}

.homePNSCardHead{
    padding-bottom: 10px;
    font-size: 30px;
    font-weight: bolder;
    color: #6C156E;
}
.homePNSCardSubhead{
    color: #6C156E;
    height: 100px;
    font-size: 20px;
    font-weight: 300;
}
.homePNSCardPara{
    text-align: justify;
}
.homePNSCardBtn{
    border: none;
    padding: 10px 30px;
    border-radius: 10px;
    color: white;
    background-color: #6C156E;
    position: absolute;
    bottom: 30px;
    left: 35px;
}



@media (max-width: 800px) {
    .homeHeaderText{
        display: none;
    }
}