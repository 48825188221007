.createAdminMainDiv{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
}


.createAdminMainDiv section{
    display: flex;
    justify-content: center;
    align-items: center;
}

.createAdminMainDiv section label{
    color: #00A3FF;
}

.createAdminMainDiv section input{
    margin: 30px;
    padding: 10px 20px;
    min-width: 300px;
    border: 1px solid #00A3FF;
    border-radius: 10px;
}

.createAdminMainDiv section button{
    padding: 10px 20px;
    color: #fff;
    background-color: #00A3FF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.createAdminMainDiv section button:hover{
    background-color: #007cc4;
}

.createAdminMainDiv section button:active{
    background-color: #005b90;
}