.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacy-policy h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .privacy-policy p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .privacy-policy strong {
    font-weight: bold;
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .privacy-policy a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  