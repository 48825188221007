.supportMainSection{
    min-height: 900px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.supportMainSection h1{
    width: 100%;
    text-align: center;
}

.supportInputsParent{
    width: 70%;
    padding: 20px;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    background-color: #f3fbff;
    justify-content: center;
}

.supportInputs{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.supportInput{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100px;
    min-width: 300px;
    margin: 20px
}

.supportInput label{
    margin: 10px;
}
.supportInput input{
    padding: 10px 20px;
    border: 0.2px solid #00a2ff;
    background-color: #fff;
    border-radius: 10px;
}

.supportInput input[type="file"]{
    padding: 0px;
}

.supportInput input[type="file"]::file-selector-button{
    border: none;
    padding: 10px 20px;
    color: white;
    background-color: #000;
}


.supportInputsParent img{
    max-width: 500px;
    margin-bottom: 70px;
    border-radius: 20px;
}


.issueArea{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.issueArea textarea{
    padding: 20px;
    margin: 20px;
    height: 100%;
    width: 100%;
    border: none;
    border: 0.2px solid #00a2ff;
    background-color: #fff;
    border-radius: 10px;
}


.submitButton{
    width: 100%;
    margin: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.submitButton button{
    padding: 10px 20px;
    border: none;
    background-color: #00A3FF;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.submitButton button:hover{
    background-color: #008dde;
}

.submitButton button:active{
    background-color: #00619a;
}