.terms-and-conditions {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 20px;
  }
  
  .terms-and-conditions h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .terms-and-conditions h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .terms-and-conditions p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .terms-and-conditions strong {
    font-weight: bold;
  }
  
  .terms-and-conditions a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-and-conditions a:hover {
    text-decoration: underline;
  }
  