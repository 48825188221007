.navbarSection {
  width: 100%;
    text-align: center;
/* <<<<<<< HEAD */
    position: fixed;
    top: 0px;
    left: 0px;
/* >>>>>>> 9230adea50de703babd94902402c14505dd56784 */
    background-color:  #e3f5ff;
    backdrop-filter: blur(50px);
    z-index: 100;
  }
  
  .Navbar{
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
     
  }
  
  .navLogo img {
    max-width: 100px; /* Set the maximum width for your logo */
  }
  
  .navLinks {
    display: flex;
    align-items: center;
  }



  .Legalbtn{
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 600;
  }
  
  .link{
    margin: 0px 10px;
    padding: 10px;
    text-decoration: none;
  }
  
  .legalContainer {
    position: relative;
    cursor: pointer;
  }
  
  .legalSection {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: fixed;
    top: 70px;
    right: -10px;
    padding: 5%;
    /* width: 250px; */
    height: 300px;
    background-color: #e3f5ff;
    animation: MenuSection 0.5s;
  }


  @keyframes MenuSection{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }

  
  .legalPageLinks{
    display: block;
    padding: 0px 20px;
    text-decoration: none;
    color: #000;
    text-align: left;
    width: 100%;
    height: 20px;
    animation: NavLinksAnimation 0.5;
  }

  .legalPageLinks:hover{
    color: #00A3FF;
  }


  @keyframes NavLinksAnimation{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
  
  /* You may want to add additional styling for responsiveness and other design considerations */
  

  .navLinks .plansBtn {
    color: white;
    background-image: linear-gradient(to right, #00A3FF, #6C156E);
    padding: 10px 30px;
    border-radius: 20px;
  }

  .menuToggleButton{
    color: #6C156E;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    /* transition: 0.3s; */
    display: none;
    
  }

  /* .menuToggleButton:hover{
    color: white;
    background-color: #6C156E;
  } */



  .toggleSideMenuSection{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: -100%;
    top: 0px;
    background-color: #dcf2ff;
    display: none;
  }



  .sideMenuIn{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0%;
    top: 0px;
    animation: side-in 0.3s;
    display: none;
  }


  .sideMenuOut{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: -100%;
    top: 0px;
    animation: side-out 0.3s;
  }


  @keyframes side-in{
    from{
      position: fixed;
      right: -100%;
    }

    to{
      position: fixed;
      right: 0%;
    }
  }

  @keyframes side-out{
    from{
      position: fixed;
      right: 0%;
    }

    to{
      position: fixed;
      right: -100%;
    }
  }







  .closeSideMenu{
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    background-color: #00A3FF;
    color: white;
    cursor: pointer;
  }
  .ToggleMenu{
    padding: 20px;
  }
  .ToggleMenu .link{
    display: block;
    padding: 20px;
    height: 20px;
    width: 200px;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .toggleLinks{
    color: #000;
    text-decoration: none;
  }
  /* .ToggleMenu{
    display: none;
  } */
  @media (max-width: 800px) {
    
    .toggleSideMenuSection, .sideMenuIn, .menuToggleButton{
      display: inline-block;
    }
    .navLinks .link{
      display: none;
    }
  }