.shipping {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .shipping h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .shipping .policy-section {
    margin-bottom: 40px;
  }
  
  .shipping h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .shipping p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .shipping ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  